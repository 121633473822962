// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';

// Internal Dependencies
import {
  CustomInput,
  DialogForm,
} from 'components/shared';
import { useCreateOrganizationRole } from 'gql/mutations';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  label: string;
}

// Local Variables
const initialValues: FormValues = {
  label: '',
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

// Component Definition
const DialogAddOrganizationRole: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const [
    createOrganizationRole,
    {
      loading,
    },
  ] = useCreateOrganizationRole(
    {
      clearCachePredicates: ['organizationRoles'],
      onCompleted: onClose,
    },
  );

  const handleSubmit = async (values: FormValues) => {
    await createOrganizationRole({
      variables: {
        input: {
          label: values.label,
        },
      },
    });
  };

  return (
    <DialogForm<FormValues>
      dialogProps={{
        fullWidth: true,
        maxWidth: 'sm',
        open: isOpen,
      }}
      initialValues={initialValues}
      isSubmitting={loading}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Add Organization Role"
    >
      <StyledTypography color="textSecondary">
        An organization role will be available when adding or editing student data.
      </StyledTypography>

      <CustomInput
        label="New Organization Role"
        name="label"
      />
    </DialogForm>
  );
};

export default DialogAddOrganizationRole;
