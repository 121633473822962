// External Dependencies
import { FC, useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';

// Internal Dependencies
import { ListItemWithSecondaryAction } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { getFullName } from 'utils';
import { useDispatch } from 'react-redux';
import { useOneClickClaimMember } from 'gql/mutations';

// Local Typings
interface Props {
  shouldSwitchToMember: boolean;
  unclaimedMembers: GQL.IMember[];
}

// Component Definition
const UnclaimedMembersList: FC<Props> = ({
  shouldSwitchToMember,
  unclaimedMembers,
}) => {
  const dispatch = useDispatch();

  const [claimMember] = useOneClickClaimMember({
    onCompleted: () => {
      if (shouldSwitchToMember) {
        window.location.href = window.location.origin;
      } else {
        dispatch(addNotification('Member claimed successfully!', 'success'));
      }
    },
  });

  const handleClickClaimMember = useCallback((memberId: string) => () => {
    claimMember({
      variables: {
        input: {
          memberId,
          shouldSwitchToMember,
        },
      },
    });
  }, [claimMember, shouldSwitchToMember]);

  return (
    <List>
      {unclaimedMembers.map((member) => (
        <ListItemWithSecondaryAction
          key={member.id}
          primaryText={`${getFullName(member)}${member.role?.label ? ` — ${member.role.label}` : ''}`}
          secondaryAction={{
            alwaysShowButton: true,
            buttonIcon: <AddIcon />,
            buttonSize: 'medium',
            buttonText: 'Claim',
            onClick: handleClickClaimMember(member.id),
          }}
          secondaryText={member.organization?.label}
        />
      ))}
    </List>
  );
};

export default UnclaimedMembersList;
