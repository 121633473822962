// External Dependencies
import {
  Button,
  IconButton,
  SelectChangeEvent,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// Internal Dependencies
import {
  Flex,
  RoleSelector,
} from 'components/shared';
import { useGetOrganization } from 'gql/queries';

// Local Dependencies
import RoleEmptyState from './RoleEmptyState';

// Local Typings
interface Props {
  onClickAddRole: () => void;
  primaryRoleId: string | null | undefined;
  secondaryRoleIds: string[] | null | undefined;
  showRoleEmptyState: boolean;
}

// Component Definition
const RoleFields: FC<Props> = ({
  onClickAddRole,
  primaryRoleId,
  secondaryRoleIds,
  showRoleEmptyState,
}) => {
  const [showAddSecondaryButton, setShowAddSecondaryButton] = useState(Boolean(primaryRoleId));

  const { data } = useGetOrganization();

  useEffect(() => {
    setShowAddSecondaryButton(Boolean(primaryRoleId));
  }, [primaryRoleId]);

  if (!data) {
    return null;
  }

  const { organization: { organizationType: { id: orgTypeId } } } = data;

  return (
    <>
      {showRoleEmptyState && (
        <RoleEmptyState
          onClickAddRole={onClickAddRole}
          orgTypeId={parseInt(orgTypeId, 10)}
        />
      )}
      {!showRoleEmptyState && (
        <RoleSelector
          excludedIds={secondaryRoleIds}
          label="Primary Role"
          name="primaryRoleId"
        />
      )}
      {primaryRoleId && (
        <FieldArray name="secondaryRoleIds">
          {(arrayHelpers): any => {
            function changeSecondaryRole(
              idx: number,
              newRoleId: string,
            ) {
              arrayHelpers.replace(idx, newRoleId);
            }

            function removeSecondaryRole(idx: number) {
              arrayHelpers.remove(idx);
            }

            const allRoleIds = [
              ...(secondaryRoleIds ?? []),
              primaryRoleId,
            ];

            return (
              <>
                {secondaryRoleIds?.map((roleId: string, index: number) => {
                  const excludedSecondaryIds = allRoleIds.filter((item, idx) => {
                    const current = idx !== index;
                    const primary = idx === allRoleIds.length - 1;
                    return current || primary;
                  });

                  return (
                    <Flex
                      flexWrap="nowrap"
                      key={roleId}
                    >
                      <RoleSelector
                        excludedIds={excludedSecondaryIds ?? null}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${index}-${roleId}`}
                        label="Secondary Role"
                        name={`secondaryRoleIds.${index}`}
                        onChange={(evt: SelectChangeEvent<unknown>) =>
                          changeSecondaryRole(index, evt.target.value as string)}
                        value={roleId}
                      />
                      <IconButton
                        onClick={() => removeSecondaryRole(index)}
                        size="large"
                      >
                        <RemoveCircleIcon color="primary" />
                      </IconButton>
                    </Flex>
                  );
                })}
                {showAddSecondaryButton && (
                  <Button
                    disabled={secondaryRoleIds?.some(
                      (id: string) => id === '' || !id,
                    )}
                    onClick={() => arrayHelpers.push('')}
                    size="small"
                    variant="outlined"
                  >
                    Add Secondary Role
                  </Button>
                )}
              </>
            );
          }}
        </FieldArray>
      )}
    </>
  );
};

export default RoleFields;
