// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Component Definition
const LinkToMyFees: FC = () => {
  const myFinancialFeesParams = useSelector(tableQueryParams('myFinancialFees'));

  return (
    <Box
      ml={2}
      mt={4}
    >
      <Typography
        role="heading"
        sx={{ mb: 2 }}
        variant="body2"
      >
        View fees assigned to you and your children
      </Typography>

      <ForwardButton navigateTo={`/${PATHS.STUDENT_FEES}${myFinancialFeesParams}`}>
        My Fees
      </ForwardButton>
    </Box>
  );
};

export default LinkToMyFees;
