// External Dependencies
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  SettingsCardWrapper,
  SettingsWrapper,
} from 'components/shared/Settings/styles';
import {
  isAdminInCurrentOrg,
  isDistrictAdmin,
} from 'state/self/selectors';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import ExtraResourcesCard from './ExtraResourcesCard';
import FamilyCard from './FamilyCard';
import ProfileCard from './ProfileCard';
import UnclaimedMembersCard from './UnclaimedMembersCard';

// Component Definition
const Profile: FC = () => {
  const isDistrictAdminUser = useSelector(isDistrictAdmin);
  const isAdminUserInCurrentOrg = useSelector(isAdminInCurrentOrg);
  const { self } = useSelfQuery();

  const hasUnclaimedMembers = Boolean(self?.matchedUnclaimedMembers?.length);

  const hasAlertFlagId = Boolean(self?.alertFlagId);

  return (
    <SettingsWrapper>
      {!hasAlertFlagId && (
        <SettingsCardWrapper>
          <ProfileCard user={self} />
        </SettingsCardWrapper>
      )}

      {!isDistrictAdminUser && (
        <SettingsCardWrapper>
          <FamilyCard />
        </SettingsCardWrapper>
      )}

      {hasUnclaimedMembers && !hasAlertFlagId && (
        <SettingsCardWrapper>
          <UnclaimedMembersCard unclaimedMembers={self?.matchedUnclaimedMembers ?? []} />
        </SettingsCardWrapper>
      )}

      {isAdminUserInCurrentOrg && !hasAlertFlagId && (
        <SettingsCardWrapper>
          <ExtraResourcesCard />
        </SettingsCardWrapper>
      )}
    </SettingsWrapper>
  );
};

export default Profile;
