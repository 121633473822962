// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import CategoryIcon from '@mui/icons-material/Category';

// Internal Dependencies
import { DynamicFields } from 'types/api';
import { useGetDynamicFields } from 'gql/queries';

// Local Dependencies
import DynamicFormField from './DynamicFormField';
import DynamicFormFieldsFormikWrapper from './DyanmicFormFieldsFormikWrapper';
import DynamicFormFieldsZeroState from './DynamicFormFieldsZeroState';
import ShowCard, { ShowCardProps } from '../ShowCard';

// Local Typings
interface Props {
  isAdmin: boolean;
  item: DynamicFields | undefined;
  organizationTypeId?: string;
  showCardProps: Partial<ShowCardProps>;
  tableRef: 'inventory_items' | 'library_items' | 'members' | 'uniforms';
}

// Component Definition
const DynamicFormFields: FC<Props> = ({
  isAdmin,
  item,
  organizationTypeId,
  showCardProps,
  tableRef,
}) => {
  const { data, loading } = useGetDynamicFields({
    organizationTypeId,
    tableRef,
  });

  const tooltipMessage = isAdmin
    ? 'Customize the fields you and your directors see'
    : 'Custom fields added by your district administrator';

  const content = (
    <ShowCard
      icon={CategoryIcon}
      isLoading={loading}
      title="Dynamic Fields"
      tooltip={tooltipMessage}
      {...showCardProps}
    >
      {data?.dynamicFields.length ? data.dynamicFields.map((field) => (
        <DynamicFormField
          field={field}
          item={item}
          key={field.id}
          readOnly={showCardProps.readOnly}
        />
      )) : (
        <DynamicFormFieldsZeroState isAdmin={isAdmin} />
      )}
    </ShowCard>
  );

  return (
    <Box mb={2}>
      {showCardProps.readOnly ? (
        content
      ) : (
        <DynamicFormFieldsFormikWrapper
          dynamicFields={data?.dynamicFields}
          isAdmin={isAdmin}
          item={item}
          showCardProps={showCardProps}
        >
          {content}
        </DynamicFormFieldsFormikWrapper>
      )}
    </Box>
  );
};

export default DynamicFormFields;
