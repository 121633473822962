// External Dependencies
import { CSVFileInfoResource } from '@presto-assistant/api_types/api/gql';
import {
  FC, ReactNode, useCallback, useMemo,
} from 'react';
import { ImportOriginalSources } from '@presto-assistant/api_types';
import { alpha, lighten } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import DownloadIcon from '@mui/icons-material/Download';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  Input,
  Select,
  SupportLink,
} from 'components/shared';
import { SHORT_APP_NAME } from 'utils/constants';
import { UseTextFieldResult } from 'hooks/useTextField';
import { isMobileOrTabletScreenSize } from 'state/device/selectors';
import { mapEnum } from 'utils/lib/map_enum';
import { useCSVTemplateDownload, useGetCSVFileInfo } from 'gql/queries/shared-queries';

// Local Typings
export interface FileUploadProps {
  canMigrateFromAnotherSystem?: boolean;
  csvFileInfoResource: CSVFileInfoResource;
  organizationTypeId?: string;
  requiredColumnsHelperText?: ReactNode;
}

interface LocalFileUploadProps {
  importMethod: ImportMethod;
  otherImportSourceLabelField: UseTextFieldResult;
  selectedImportSourceId: number | null;
  setImportMethod: (value: ImportMethod) => void;
  setSelectedImportSourceId: (value: number | null) => void;
  tipMessage?: ReactNode;
  tipTitle?: string;
}

export type ImportMethod = 'template' | 'manual' | 'migration';

// Local Variables
const importOriginalSourceOptions = mapEnum(ImportOriginalSources);

const StyledRoot = styled.div(({ theme }) => {
  const commonHeaderStyles = {
    margin: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    padding: theme.spacing(1.5),
  };

  return {
    '.importList': {
      '& li': {
        '& .MuiAvatar-root': {
          [theme.breakpoints.down('md')]: {
            height: theme.spacing(4),
            marginRight: theme.spacing(1.5),
            width: theme.spacing(4),
          },
          backgroundColor: theme.palette.stripeBlue['400'],
          fontWeight: 600,
          height: theme.spacing(5),
          marginRight: theme.spacing(2),
          width: theme.spacing(5),
        },
        '& .MuiCardContent-root': {
          display: 'flex',
        },
        '& .csvDirections': {
          display: 'flex',
          flexDirection: 'column',
        },

        '& .downloadTemplateButton': {
          [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginTop: theme.spacing(2),
          },
          marginLeft: theme.spacing(2),
        },
        '& .downloadTemplateElement': {
          [theme.breakpoints.down('md')]: {
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
          },
        },

        '&:not(:last-child)': {
          marginBottom: theme.spacing(2),
        },

        '.listItemCard': {
          backgroundColor: lighten(theme.palette.grey[50], 0.05),
          width: '100%',
        },

        alignItems: 'baseline',
        display: 'flex',
      },

      counterReset: 'item',
      display: 'flex',
      flexDirection: 'column',
      listStyle: 'none',
      margin: 0,
      paddingLeft: theme.spacing(0.5),
    },

    '.importSubtitle': {
      fontSize: '1.1rem',
      marginBottom: theme.spacing(1.5),
    },
    '.optionalHeaders': {
      ...commonHeaderStyles,
      backgroundColor: alpha(theme.palette.stripeBlue['300'], 0.2),
    },
    '.requiredHeaders': {
      ...commonHeaderStyles,
      backgroundColor: theme.palette.dashboardOrgName,
    },
  };
});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    {...props}
  />
))(({ theme }) => ({
  '&:before': {
    display: 'none',
  },

  '&:not(:last-child)': {
    borderBottom: 0,
  },

  border: `1px solid ${theme.palette.grey['400']}`,
  overflow: 'hidden',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.stripeBlue['700']
      : theme.palette.relationships.avatar,
  flexDirection: 'row-reverse',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  padding: theme.spacing(2),
}));

// Component Definition
const FileUpload: FC<FileUploadProps & LocalFileUploadProps> = ({
  canMigrateFromAnotherSystem,
  csvFileInfoResource,
  importMethod,
  organizationTypeId,
  otherImportSourceLabelField,
  requiredColumnsHelperText,
  selectedImportSourceId,
  setImportMethod,
  setSelectedImportSourceId,
  tipMessage,
  tipTitle,
}) => {
  const isMobileOrTabletScreen = useSelector(isMobileOrTabletScreenSize);

  const {
    data,
    loading,
  } = useGetCSVFileInfo(csvFileInfoResource, organizationTypeId);

  const [
    downloadTemplate,
    {
      loading: isDownloading,
    },
  ] = useCSVTemplateDownload(csvFileInfoResource, organizationTypeId);

  const handleDownloadTemplate = useCallback(() => downloadTemplate(), [downloadTemplate]);

  const troubleshootingLinkElement = useMemo(() => (
    <Typography variant="body2">
      If you encounter any issues, please refer to{' '}
      <SupportLink
        buttonText="this support article"
        slug="import-student-data#troubleshooting"
      />.
    </Typography>
  ), []);

  const handleSelectImportSource = useCallback((event) => {
    setSelectedImportSourceId(Number(event.target.value) as ImportOriginalSources);
  }, [setSelectedImportSourceId]);

  const handleChangeAccordionPanel = useCallback((newImportMethod: ImportMethod) => () => {
    setImportMethod(newImportMethod);
  }, [setImportMethod]);

  const templateSteps = useMemo(() => (
    <Accordion
      expanded={importMethod === 'template'}
      onChange={handleChangeAccordionPanel('template')}
    >
      <AccordionSummary
        aria-controls="template-content"
        id="template-header"
      >
        <Typography sx={{ fontWeight: 500, marginRight: 1 }}>
          Option 1 •
        </Typography>
        <Typography>Use our template</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <ol className="importList">
          <li>
            <EnhancedCard
              className="listItemCard"
              useCardContent
            >
              <Avatar>
                1
              </Avatar>

              {isDownloading
                ? (
                  <Box mx={4}>
                    <CircularProgress size={20} />
                  </Box>
                )
                : (
                  <Typography className="downloadTemplateElement">
                    Download the {SHORT_APP_NAME} template to get started.
                    <Button
                      className="downloadTemplateButton"
                      onClick={handleDownloadTemplate}
                      size={isMobileOrTabletScreen ? 'small' : 'large'}
                      startIcon={<DownloadIcon />}
                      variant="outlined"
                    >
                      Download
                    </Button>
                  </Typography>
                )}
            </EnhancedCard>
          </li>

          <li>
            <EnhancedCard
              className="listItemCard"
              useCardContent
            >
              <Avatar>2</Avatar>

              <div>
                <Typography gutterBottom>
                  Add your data to the template and drop it into the box below.
                </Typography>

                {troubleshootingLinkElement}
              </div>
            </EnhancedCard>
          </li>
        </ol>
      </AccordionDetails>
    </Accordion>
  ), [
    handleChangeAccordionPanel,
    handleDownloadTemplate,
    importMethod,
    isDownloading,
    isMobileOrTabletScreen,
    troubleshootingLinkElement,
  ]);

  const manualSteps = useMemo(() => (
    <Accordion
      expanded={importMethod === 'manual'}
      onChange={handleChangeAccordionPanel('manual')}
    >
      <AccordionSummary
        aria-controls="manual-content"
        id="manual-header"
      >
        <Typography sx={{ fontWeight: 500, marginRight: 1 }}>
          Option 2 •
        </Typography>
        <Typography>Use your file</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <ol className="importList">
          <li>
            <EnhancedCard
              className="listItemCard"
              useCardContent
            >
              <Avatar>1</Avatar>

              <div className="csvDirections">
                <Typography>
                  Add a Comma Separated Value (
                  <strong>.CSV</strong>
                  ) file with the following{' '}
                  <strong>REQUIRED</strong> column headers:
                </Typography>

                <EnhancedCard
                  className="requiredHeaders"
                >
                  <Typography>{data && data?.csvFileInfo?.requiredHeaders.join(', ')}</Typography>
                </EnhancedCard>

                {requiredColumnsHelperText && (
                <Typography sx={{ mb: 2 }}>{requiredColumnsHelperText}</Typography>
                )}

                {data && data?.csvFileInfo?.optionalHeaders.length > 0 && (
                <>
                  <Typography>
                    You may also include the following <strong>OPTIONAL</strong> column headers:
                  </Typography>

                  <EnhancedCard
                    className="optionalHeaders"
                  >
                    {data?.csvFileInfo?.optionalHeaders.map((header, index) => (
                      <Typography
                        component="span"
                        key={header}
                      >
                        {header}
                        {index !== (data?.csvFileInfo?.optionalHeaders.length ?? 0) - 1 && ','}{' '}
                      </Typography>
                    ))}
                  </EnhancedCard>
                </>
                )}
              </div>
            </EnhancedCard>
          </li>

          <li>
            <EnhancedCard
              className="listItemCard"
              useCardContent
            >
              <Avatar>2</Avatar>

              <div>
                <Typography gutterBottom>
                  Drop your CSV file into the box below.
                </Typography>

                {troubleshootingLinkElement}
              </div>
            </EnhancedCard>
          </li>
        </ol>
      </AccordionDetails>
    </Accordion>
  ), [
    data,
    handleChangeAccordionPanel,
    importMethod,
    requiredColumnsHelperText,
    troubleshootingLinkElement,
  ]);

  const migrationSteps = useMemo(() => (
    <Accordion
      expanded={importMethod === 'migration'}
      onChange={handleChangeAccordionPanel('migration')}
    >
      <AccordionSummary
        aria-controls="migration-content"
        id="migration-header"
      >
        <Typography sx={{ fontWeight: 500, marginRight: 1 }}>
          Option 3 •
        </Typography>
        <Typography>Import from another system</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <ol className="importList">
          <li>
            <EnhancedCard
              className="listItemCard"
              useCardContent
            >
              <Avatar>1</Avatar>

              <Box flexGrow={1}>
                <Typography
                  className="downloadTemplateElement"
                  paragraph
                >
                  Select the system you are migrating from.
                </Typography>

                <Box
                  display="flex"
                  gap={2}
                >
                  <Select
                    fullWidth={false}
                    label="System"
                    name="system"
                    onChange={handleSelectImportSource}
                    options={importOriginalSourceOptions}
                    size="small"
                    value={selectedImportSourceId ?? 0}
                  />

                  {selectedImportSourceId === ImportOriginalSources.Other && (
                  <Input
                    {...otherImportSourceLabelField}
                    label="System Name"
                    margin="none"
                    size="small"
                    variant="filled"
                  />
                  )}
                </Box>
              </Box>
            </EnhancedCard>
          </li>

          <li>
            <EnhancedCard
              className="listItemCard"
              useCardContent
            >
              <Avatar>2</Avatar>

              <div>
                <Typography paragraph>
                  After you have exported your data from the old system, drop it into the box below.
                </Typography>

                {troubleshootingLinkElement}
              </div>
            </EnhancedCard>
          </li>

          <li>
            <EnhancedCard
              className="listItemCard"
              useCardContent
            >
              <Avatar>3</Avatar>

              <div>
                <Typography paragraph>
                  After you successfully import your file, a {SHORT_APP_NAME} team member
                  will review your data and contact you if there are any issues.
                </Typography>

                <Typography>
                  Please allow <strong>up to 2 business days</strong> for your data to be reviewed.
                </Typography>
              </div>
            </EnhancedCard>
          </li>
        </ol>
      </AccordionDetails>
    </Accordion>
  ), [
    handleChangeAccordionPanel,
    handleSelectImportSource,
    importMethod,
    otherImportSourceLabelField,
    selectedImportSourceId,
    troubleshootingLinkElement,
  ]);

  if (loading) {
    return (
      <CircularProgress />
    );
  }

  if (!data) {
    return null;
  }

  return (
    <StyledRoot>
      <Typography
        className="importSubtitle"
        component="h2"
      >
        <strong>Need to add data?</strong>{' '}
      </Typography>

      <Typography>
        No sweat! Follow the steps below to get up and running fast.{' '}
        <SupportLink slug="import-student-data" />
      </Typography>

      <Box marginY={2}>
        {templateSteps}

        {manualSteps}

        {canMigrateFromAnotherSystem ? migrationSteps : null}
      </Box>

      {tipMessage && (
        <Collapse in={importMethod !== 'migration'}>
          <EnhancedAlert
            isTip
            sx={{ marginTop: 2 }}
            title={tipTitle}
          >
            {tipMessage}
          </EnhancedAlert>
        </Collapse>
      )}
    </StyledRoot>
  );
};

export default FileUpload;
