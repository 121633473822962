// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  SettingsCardWrapper,
  SettingsWrapper,
} from 'components/shared/Settings/styles';

// Local Dependencies
import OrganizationCard from './OrganizationCard';
import OrganizationIntegrationsCard from './OrganizationIntegrationsCard';
import SettingsDistrictData from './SettingsDistrictData';

// Component Definition
const Settings: FC = () => (
  <SettingsWrapper>
    <SettingsCardWrapper>
      <OrganizationCard />
    </SettingsCardWrapper>

    <SettingsCardWrapper>
      <SettingsDistrictData />
    </SettingsCardWrapper>

    <SettingsCardWrapper fullWidth>
      <OrganizationIntegrationsCard />
    </SettingsCardWrapper>
  </SettingsWrapper>
);

export default Settings;
