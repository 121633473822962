// External Dependencies
import { FC } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';

// Internal Dependencies
import { getIsMaintenanceMode } from 'utils/lib/get_is_maintenance_mode';
import { getToken } from 'utils/cookies';
import Maintenance from 'pages/Maintenance/index';

// Local Typings
interface Props extends RouteComponentProps {
  component: any;
}

// Component Definition
const NoAuth: FC<Props> = ({
  component: Component,
  location,
  ...rest
}) => {
  const isLoggedIn = Boolean(getToken());

  if (getIsMaintenanceMode()) {
    return <Maintenance />;
  }

  if (isLoggedIn) {
    return (
      <Redirect
        from={location?.pathname}
        noThrow
        to="/"
      />
    );
  }
  return <Component {...rest} />;
};

export default NoAuth;
