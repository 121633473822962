// External Dependencies
import { Link } from '@reach/router';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import Logo from 'assets/images/logo.png';

// Local Variables
const useStyles = makeStyles({
  image: {
    paddingLeft: 30,
  },
  root: {
    margin: '48px 0',
    textAlign: 'center',
    textDecoration: 'none',
  },
});

// Component Definition
const MainLogo = () => {
  const classes = useStyles();
  return (
    <Link
      className={classes.root}
      to="/"
    >
      <img
        alt="Presto logo."
        className={classes.image}
        src={Logo}
        width={80}
      />
    </Link>
  );
};

export default MainLogo;
