// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';

// Local Dependencies
import LinkToInactiveUsers from '../../shared/LinkToInactiveUsers';
import LinkToPreviousYearMembers from '../../shared/LinkToPreviousYearMembers';
import ParentTable from './ParentTable';

// Component Definition
const Parents = () => (
  <>
    <Subtitle>
      Parents
    </Subtitle>

    <EnhancedCard>
      <ParentTable />
    </EnhancedCard>

    <LinkToInactiveUsers />

    <LinkToPreviousYearMembers />
  </>
);

export default Parents;
