// External Dependencies
import { FC } from 'react';

// Local Dependencies
import DistrictZeroState from './DistrictZeroState';
import OrganizationZeroState from './OrganizationZeroState';

// Local Typings
interface Props {
  isAdmin: boolean;
}

// Component Definition
const DynamicFormFieldsZeroState: FC<Props> = ({ isAdmin }) =>
  (isAdmin ? <DistrictZeroState /> : <OrganizationZeroState />);

export default DynamicFormFieldsZeroState;
