// External Dependencies
import { FC } from 'react';

// Local Dependencies
import { DynamicFields } from 'types/api';
import { ShowPageDataDisplay } from 'components/shared/index';
import { formatDyanmicFieldValue } from './ShowPageDynamicDataDisplay';
import CustomDynamicFormField from './CustomDynamicFormField';

// Local Typings
interface Props {
  field: GQL.IDynamicField;
  item: DynamicFields | undefined;
  readOnly?: boolean;
}

// Component Definition
const DynamicFormField: FC<Props> = ({
  field,
  item,
  readOnly,
}) => (readOnly
  ? (
    <ShowPageDataDisplay
      label={field.label}
      value={formatDyanmicFieldValue(field, item?.[field.dynamicFieldRef as keyof DynamicFields] ?? '')}
    />
  )
  : <CustomDynamicFormField field={field} />
);

export default DynamicFormField;
