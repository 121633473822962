// External Dependencies
import Auth, { AuthRouteProps } from 'components/shared/Auth';
import FourOhFour from 'pages/FourOhFour';

const mapRoutes = (route: AuthRouteProps, index: number): JSX.Element => (
  <Auth
    component={route.component}
    default={route.default}
    hasPermission={route.hasPermission}
    key={`${route.path}-${index}`}
    path={route.path}
  />
);

export const renderRoutes = (routes: AuthRouteProps[]) => [
  ...routes,
  {
    component: FourOhFour,
    default: true,
  },
].map(mapRoutes);
