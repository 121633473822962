// External Dependencies
import { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { StandaloneSelect } from 'components/shared';
import { useGetOrganizationTypes } from 'gql/queries';
import useSelectField from 'hooks/useSelectField';

// Local Typings
interface Props {
  context: string;
  initialValue?: string;
  onSetValue: (value: string) => void;
}

// Component Definition
const OrganizationTypeSelect: FC<Props> = ({
  context,
  initialValue = '1',
  onSetValue,
}) => {
  const { data: orgTypeData } = useGetOrganizationTypes();

  const selectField = useSelectField(initialValue);

  useEffect(() => {
    onSetValue(selectField.value);
  }, [onSetValue, selectField.value]);

  return (
    <>
      <Typography>
        {APP_NAME} allows importing {context} items for each type of organization all at once.
      </Typography>

      <Typography
        sx={{
          mb: 1,
          mt: 1,
        }}
      >
        Select the desired organization type:
      </Typography>

      {orgTypeData && (
        <Box maxWidth={400}>
          <StandaloneSelect
            id="organization-type"
            label="Organization Type"
            name="organizationType"
            options={orgTypeData.organizationTypes}
            {...selectField}
          />
        </Box>
      )}
    </>
  );
};

export default OrganizationTypeSelect;
