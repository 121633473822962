// External Dependencies
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { addNotification } from 'state/notifications/actions';
import { pluralize } from 'utils';
import { useIsOpen } from 'hooks/useIsOpen';
import { useSendFinancialStatements } from 'gql/mutations';
import useTextField from 'hooks/useTextField';

// Local Dependencies
import { Checkbox, SaveButton, StyledLink } from '..';

// Local Typings
interface UserProps {
  isStudent?: boolean;
  memberId?: string;
  memberIds?: never;
}
interface NoUserProps {
  isStudent?: never;
  memberId?: never;
  memberIds?: string[] | null;
}
interface BaseProps {
  isOpen: boolean;
  onClose: () => void;
}
type Props = BaseProps & UserProps | BaseProps & NoUserProps;

// Local Variables
const maxMessageLength = 1000;

// Component Definition
const SendFinancialStatementDialog: FC<Props> = ({
  isOpen,
  isStudent,
  memberId,
  memberIds,
  onClose,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const messageField = useTextField('');
  const {
    handleOpen: handleResetIncludeOnlyMembersWithBalance,
    isOpen: includeOnlyMembersWithBalance,
    toggleIsOpen: handleChangeIncludeOnlyMembersWithBalance,
  } = useIsOpen(true);

  const isSendingToOneMember = Boolean(memberId) || memberIds?.length === 1;

  const dispatch = useDispatch();

  const handleSubmitSuccess = useCallback(() => {
    dispatch(addNotification('Statements sent!', 'success'));
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    handleResetIncludeOnlyMembersWithBalance();
    messageField.onReset();
    setHasSubmitted(false);
  // The linter wants the entire messageField object. We're not doing that.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResetIncludeOnlyMembersWithBalance, messageField?.onReset, isOpen]);

  const [sendFinancialStatements, {
    loading: isSubmitting,
  }] = useSendFinancialStatements({
    onCompleted: handleSubmitSuccess,
  });

  const handleSubmit = useCallback(() => {
    setHasSubmitted(true);

    if (messageField.value.trim().length) {
      sendFinancialStatements({
        variables: {
          input: {
            includeOnlyMembersWithBalance: !isSendingToOneMember && includeOnlyMembersWithBalance,
            memberId,
            memberIds: memberIds?.length ? memberIds : null,
            message: messageField.value.trim().replaceAll('\n', '<br />'),
          },
        },
      });
    }
  }, [
    includeOnlyMembersWithBalance,
    isSendingToOneMember,
    memberId,
    memberIds,
    messageField.value,
    sendFinancialStatements,
  ]);

  const trimmedMessage = messageField.value.trim();
  const hasError = (hasSubmitted && !trimmedMessage) || trimmedMessage.length > maxMessageLength;
  const helperText = hasSubmitted && !trimmedMessage
    ? 'Required'
    : `${messageField.value.length} / ${maxMessageLength} characters`;

  const contentText = useMemo(() => {
    if (memberIds?.length) {
      return `${memberIds.length} ${pluralize(memberIds.length, 'member')} will receive a statement showing any fees due and payment history.`;
    }

    if (!memberId) {
      return 'All students and their parents will receive a statement showing any fees due and payment history, including those with no balance due. Any adults in the organization who have fees assigned will also receive a statement.';
    }

    if (isStudent) {
      return 'This student and their parents will receive a statement showing any fees due and payment history, even if they have no balance due.';
    }

    return 'This user will receive a statement showing any fees due and payment history, even if they have no balance due.';
  }, [isStudent, memberId, memberIds]);

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
    >
      <DialogTitle>
        {isSendingToOneMember ? 'Email Financial Statement' : 'Email Financial Statements'}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>

        <DialogContentText sx={{ my: 1.5 }}>
          <StyledLink
            aria-label="Financial statement email sample"
            href="/downloads/financial_statement_sample.png"
            openInNewTab
          >
            Preview sample email
          </StyledLink>
        </DialogContentText>

        <TextField
          {...messageField}
          error={hasError}
          helperText={helperText}
          fullWidth
          label="Message"
          multiline
          placeholder="Message to include..."
          rows={10}
          variant="outlined"
        />

        {!isSendingToOneMember && (
          <Box sx={{ mt: 2 }}>
            <Checkbox
              checked={includeOnlyMembersWithBalance}
              label="Only send to members with a balance"
              onChange={handleChangeIncludeOnlyMembersWithBalance}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>

        <SaveButton
          isSaving={isSubmitting}
          onClick={handleSubmit}
        >
          Send
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};

export default SendFinancialStatementDialog;
