// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';
import { HistoryResource } from '@presto-assistant/api_types';

// Internal Dependencies
import { EnhancedCard, Subtitle } from 'components/shared';
import { useGetHistory } from 'gql/queries';

// Local Dependencies
import HistoryTimeline from './HistoryTimeline';
import ShowHistoryButton from './ShowHistoryButton';

// Local Typings
interface Props {
  id: string;
  resource: HistoryResource;
}

// Component Definition
const History: FC<Props> = ({
  id,
  resource,
}) => {
  const [
    query,
    {
      data,
      fetchMore,
      loading,
    },
  ] = useGetHistory({
    id,
    resource,
  });

  const handleClickShowMoreButton = () => {
    if (fetchMore) {
      fetchMore({
        variables: {
          id,
          nextCursor: data?.history.nextCursor,
          resource,
        },
      });
    }
  };

  return (
    <Box mt={2}>
      <Subtitle>
        History
      </Subtitle>

      <EnhancedCard>
        {data?.history ? (
          <HistoryTimeline
            historyDataResponse={data.history}
            isLoading={loading}
            onClickShowMoreButton={handleClickShowMoreButton}
          />
        ) : (
          <ShowHistoryButton
            isLoading={loading}
            query={query}
          />
        )}
      </EnhancedCard>
    </Box>
  );
};

export default History;
