// External Dependencies
import { Box } from '@mui/material';
import { FC } from 'react';

// Component Definition
const BottomContainer: FC = ({ children }) => (
  <Box
    display="flex"
    justifyContent="center"
    p={2}
  >
    {children}
  </Box>
);

export default BottomContainer;
