// External Dependencies
import { FC } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  SvgIconTypeMap,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import MusicOffIcon from '@mui/icons-material/MusicOff';

// Local Typings
interface Props {
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  htmlColor?: string;
}

// Component Definition
const ZeroStateIcon: FC<Props> = ({
  Icon = MusicOffIcon,
  htmlColor,
}: Props) => {
  const theme = useTheme();

  const iconColor = htmlColor || theme.palette.grey[400];

  return (
    <Icon
      htmlColor={alpha(iconColor, 0.8)}
      sx={{
        height: 96,
        width: 96,
      }}
    />
  );
};

export default ZeroStateIcon;
