// External Dependencies
import { FC } from 'react';
import { useLocation } from '@reach/router';

// Internal Dependencies
import { navigateSearch } from 'utils/lib/navigate_search';
import { parseSearch } from 'utils';
import { useGetOrganizationSchoolYearOptions } from 'gql/queries';
import FilterButton from 'components/shared/FilterButton';

// Local Typings
export interface IFilters {
  schoolYearEnding: string | null;
}

// Component Definition
const Filters: FC = () => {
  const { search } = useLocation();

  const parsedSearch = parseSearch(search);

  const { data } = useGetOrganizationSchoolYearOptions({
    includeAllYearsOption: true,
  });

  const localFilters: IFilters = {
    schoolYearEnding: parsedSearch.schoolYearEnding ?? null,
  };

  const handleClickFilter = (
    key: 'schoolYearEnding',
    val: string | null,
  ) => () => {
    localFilters[key] = val as never;
    navigateSearch({
      ...localFilters,
      page: 1,
    });
  };

  const getSchoolYearButtonLabel = () => {
    const schoolYear = data?.organizationSchoolYearSelectOptions
      .find((schoolYearOption) =>
        schoolYearOption.id.toString() === localFilters.schoolYearEnding?.toString());

    return schoolYear?.label ?? 'School Year';
  };

  return (
    <div>
      {data && (
        <FilterButton
          active={localFilters.schoolYearEnding !== null}
          buttonChildren={getSchoolYearButtonLabel()}
          id="category"
          onClick={localFilters.schoolYearEnding !== null
            ? handleClickFilter('schoolYearEnding', null)
            : null}
          subButtons={data.organizationSchoolYearSelectOptions.map((option) => ({
            onClick: handleClickFilter('schoolYearEnding', option.id.toString()),
            selected: localFilters.schoolYearEnding?.toString() === option.id.toString(),
            text: option.label,
          }))}
        />
      )}
    </div>
  );
};

export default Filters;
