// External Dependencies
import { FC, useEffect, useState } from 'react';
import { SelectProps } from '@mui/material';

// Internal Dependencies
import { getStates } from 'utils/api';

// Local Dependencies
import CustomSelect from '../CustomSelect';

// Local Typings
interface Props extends SelectProps {
  label?: string;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
}

// Component Definition
const StateSelector: FC<Props> = ({
  label = 'State',
  name = 'stateId',
  readOnly = false,
  required = false,
  ...otherProps
}) => {
  const [stateOptions, setStateOptions] = useState<DB.State[]>([]);

  useEffect(() => {
    const getStateOptions = async () => {
      const response = await getStates();
      const { data } = response;
      if (data?.states) {
        setStateOptions(data.states);
      }
    };

    getStateOptions();
  }, []);

  if (!stateOptions.length) {
    return null;
  }

  return (
    <CustomSelect
      inputProps={{ readOnly }}
      label={label}
      name={name}
      options={stateOptions}
      required={required}
      {...otherProps}
    />
  );
};

export default StateSelector;
