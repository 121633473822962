// External Dependencies
import { FC } from 'react';
import {
  InputAdornment,
  TableCell,
} from '@mui/material';

// Internal Dependencies
import { MoneyInput } from 'components/shared';
import { StyledTableCellTextBox, StyledTableRow } from 'pages/Finances/FinancialPayments/shared/styles';
import { convertCentsToDollars, displayPriceStringFromDollarAmount } from 'utils';
import { useFormikTextField } from 'hooks/useFormikTextField';
import PaymentFormTableInput from 'pages/Finances/FinancialPayments/shared/PaymentFormTableInput';

// Local Typings
export interface RowValues {
  amountInDollars: number;
  balanceDueInCents: number;
  financialFeeId: string;
  isOrganizationCoveringStripeFee: boolean;
  itemLabel: string;
  userName: string;
}
interface Props extends RowValues {
  hasMixedFees: boolean;
  rowIndex: number;
}

// Local Variables
const startAdornment = <InputAdornment position="start">$</InputAdornment>;

// Component Definition
const PaymentTableRow: FC<Props> = ({
  amountInDollars,
  balanceDueInCents,
  hasMixedFees,
  isOrganizationCoveringStripeFee,
  itemLabel,
  rowIndex,
  userName,
}) => {
  const amountField = useFormikTextField(`payments[${rowIndex}].amountInDollars`, amountInDollars.toLocaleString());

  return (
    <StyledTableRow $cellVerticalAlign="middle">
      <TableCell>
        {userName}
      </TableCell>

      <TableCell>
        {itemLabel}
      </TableCell>

      {hasMixedFees && (
        <TableCell>
          {isOrganizationCoveringStripeFee ? 'No' : 'Yes'}
        </TableCell>
      )}

      <TableCell align="right">
        <StyledTableCellTextBox>
          {displayPriceStringFromDollarAmount(
            convertCentsToDollars(balanceDueInCents),
          )}
        </StyledTableCellTextBox>
      </TableCell>

      <TableCell>
        <PaymentFormTableInput
          InputProps={{
            inputComponent: MoneyInput as any,
            inputProps: {
              'aria-label': 'amount',
            },
            startAdornment,
          }}
          {...amountField}
        />
      </TableCell>
    </StyledTableRow>
  );
};

export default PaymentTableRow;
