// External Dependencies
import { useMemo } from 'react';

// Internal Dependencies
import { GET_SELF } from 'gql/queries';
import client from 'gql/client';

// Hook Definition
const useCanEditPermissions = () => {
  // We want this to only pull from Apollo cache
  const selfResult = client.readQuery({ query: GET_SELF });

  const self: GQL.ISelf | null = selfResult?.self;

  const currentOrg = useMemo(
    () =>
      self?.userOrgData.find((uo) => uo.organizationId === self.currentOrgId
      && uo.member?.id === self.id),
    [self],
  );

  return currentOrg?.mayEditPermissions;
};

export default useCanEditPermissions;
