// External Dependencies
import { FC, ReactElement } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { getSearchWithRedirectUrl } from 'utils/lib/redirect_user';
import { getToken } from 'utils/cookies';
import { isDistrictAdmin as isDistrictAdminSelector, tokenData } from 'state/self/selectors';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface Props extends RouteComponentProps {
  children: ReactElement | ReactElement[];
  userRole: 'organizationUser' | 'districtAdmin' | 'orphanUser';
}

// Component Definition
const RoleRouter: FC<Props> = ({
  children,
  location,
  userRole,
}) => {
  const isLoggedIn = !!getToken();

  const isDistrictAdmin = useSelector(isDistrictAdminSelector);
  const hasTokenData = !!useSelector(tokenData);

  const { self } = useSelfQuery();

  if (!isLoggedIn) {
    const queryString = getSearchWithRedirectUrl();

    return (
      <Redirect
        location={location ? {
          ...location,
          search: queryString ? `?${queryString}` : '',
        } : undefined}
        noThrow
        to="/"
      />
    );
  }

  if (!self) {
    return null;
  }

  const isOrganizationUser = Boolean(self.currentOrgId);
  const isOrphanUser = !isDistrictAdmin && !isOrganizationUser;

  // We want to make sure we have token data, as we depend on that
  // to determine if the user is a DFA.
  if (self && !hasTokenData) {
    return null;
  }

  let homePath = '/';

  if (isDistrictAdmin && userRole === 'districtAdmin') {
    return children as any;
  }

  if (isOrganizationUser && userRole === 'organizationUser') {
    return children as any;
  }

  if (isOrphanUser && userRole === 'orphanUser') {
    return children as any;
  }

  if (isDistrictAdmin) {
    homePath = `/${PATHS.DISTRICT_ADMIN}/${PATHS.DASHBOARD}`;
  } else if (isOrganizationUser) {
    homePath = `/${PATHS.DASHBOARD}`;
  } else {
    homePath = `/${PATHS.MISSING_ORGANIZATION}/${PATHS.FIND_ORGANIZATION}`;
  }

  return (
    <Redirect
      noThrow
      to={homePath}
    />
  );
};

export default RoleRouter;
