export const imageFileExtensions = [
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'gif',
  'heic',
];

export const UPLOAD_FILE_TYPES = {
  csv: '.csv, text/csv',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
};

export const ONE_MEGABYTE_IN_BYTES = 1_000_000;
