// External Dependencies
import { FC } from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';

// Internal Dependencies
import { getToken } from 'utils/cookies';
import FourOhFour from 'pages/FourOhFour';

// Local Typings
export interface AuthRouteProps extends RouteComponentProps {
  component: any;
  hasPermission?: boolean;
}

// Component Definition
const Auth: FC<AuthRouteProps> = ({
  component: Component,
  hasPermission,
  location,
  ...rest
}) => {
  const isLoggedIn = Boolean(getToken());

  if (!isLoggedIn) {
    return (
      <Redirect
        from={location?.pathname}
        noThrow
        to="/login"
      />
    );
  }

  if (hasPermission === false) {
    return <FourOhFour />;
  }

  return <Component {...rest} />;
};

export default Auth;
