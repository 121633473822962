// External Dependencies
import { getOperationName } from '@apollo/client/utilities';
import gql from 'graphql-tag';

// Internal Dependencies
import { FRAGMENT_USER_RELATIONSHIP, GET_USER_RELATIONSHIPS } from 'gql/queries/relationship-queries';
import { MutationOptions, useMutationEnhanced } from 'utils/lib/graphql';

// Local Typings
interface CreateRelationshipResponse extends Pick<
  GQL.IUserRelationship,
  'createdAt' | 'id' | 'isConfirmed' | 'isPending' | 'isRejected' | 'updatedAt'
> {
  recipient: Pick<GQL.IUser, 'email' | 'firstName' | 'id' | 'lastName'>;
  recipientType: Pick<GQL.IRelationshipType, 'id' | 'label'>;
  sender: Pick<GQL.IUser, 'email' | 'firstName' | 'id' | 'lastName'>;
  senderType: Pick<GQL.IRelationshipType, 'femaleLabel' | 'id' | 'label' | 'maleLabel'>;
}

interface CreateRelationshipData {
  createRelationship: CreateRelationshipResponse;
}
interface CreateRelationshipByMemberWithPermissionData {
  createRelationshipByMemberWithPermission: GQL.IUserRelationship;
}
interface DeleteRelationshipData {
  deleteRelationship: boolean;
}
interface UpdateParentalConsentData {
  updateParentalConsent: boolean;
}

export const CREATE_RELATIONSHIP = gql`
  mutation CreateRelationship (
    $recipientEmail: String!
    $recipientFirstName: String!
    $recipientLastName: String!
    $recipientRelationshipTypeId: ID
  ) {
    createRelationship(
      recipientEmail: $recipientEmail
      recipientFirstName: $recipientFirstName
      recipientLastName: $recipientLastName
      recipientRelationshipTypeId: $recipientRelationshipTypeId
    ) {
      ...RelationshipFragment
    }
  }
  ${FRAGMENT_USER_RELATIONSHIP}
`;

const CREATE_RELATIONSHIP_BY_MEMBER_WITH_PERMISSION = gql`
  mutation CreateRelationshipByMemberWithPermission (
    $input: CreateRelationshipByMemberWithPermissionInput!
  ) {
    createRelationshipByMemberWithPermission(
      input: $input
    ) {
      ...RelationshipFragment
    }
  }
  ${FRAGMENT_USER_RELATIONSHIP}
`;

const DELETE_RELATIONSHIP = gql`
  mutation DeleteRelationship(
    $input: DeleteRelationshipInput!
  ) {
    deleteRelationship(
      input: $input
    )
  }
`;

export const RESPOND_TO_RELATIONSHIP_REQUEST = gql`
  mutation RespondToRelationshipRequest (
    $id: ID!
    $isConfirmed: Boolean!
  ) {
    respondToRelationshipRequest(
      id: $id
      isConfirmed: $isConfirmed
    ) {
      ...RelationshipFragment
    }
  }
  ${FRAGMENT_USER_RELATIONSHIP}
`;

const UPDATE_PARENTAL_CONSENT = gql`
  mutation UpdateParentalConsent(
    $input: UpdateParentalConsentInput!
  ) {
    updateParentalConsent(
      input: $input
    )
  }
`;

export const useCreateRelationship = (
  options?: MutationOptions<
    CreateRelationshipData,
    GQL.ICreateRelationshipOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateRelationshipData,
    GQL.ICreateRelationshipOnMutationArguments>(
      CREATE_RELATIONSHIP,
      {
        ...options,
        clearCachePredicates: [
          ...(options?.clearCachePredicates ?? []),
          'myChildrenUnderThirteen',
          'userRelationships',
        ],
        refetchQueries: [
          getOperationName(GET_USER_RELATIONSHIPS) as string,
        ],
      },
    );

export const useCreateRelationshipByMemberWithPermission = (
  options?: MutationOptions<
    CreateRelationshipByMemberWithPermissionData,
    GQL.ICreateRelationshipByMemberWithPermissionOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    CreateRelationshipByMemberWithPermissionData,
    GQL.ICreateRelationshipByMemberWithPermissionOnMutationArguments>(
      CREATE_RELATIONSHIP_BY_MEMBER_WITH_PERMISSION,
      {
        ...options,
        clearCachePredicates: [
          ...(options?.clearCachePredicates ?? []),
          'myChildrenUnderThirteen',
          'relationships',
          'studentsIndex',
          'user',
          'userRelationships',
        ],
        refetchQueries: [
          getOperationName(GET_USER_RELATIONSHIPS) as string,
        ],
      },
    );

export const useDeleteRelationship = (
  options?: MutationOptions<
    DeleteRelationshipData,
    GQL.IDeleteRelationshipOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    DeleteRelationshipData,
    GQL.IDeleteRelationshipOnMutationArguments>(
      DELETE_RELATIONSHIP,
      {
        ...options,
        clearCachePredicates: [
          ...(options?.clearCachePredicates ?? []),
          'myChildrenUnderThirteen',
          'userRelationships',
        ],
        refetchQueries: [
          getOperationName(GET_USER_RELATIONSHIPS) as string,
        ],
      },
    );

export const useUpdateParentalConsent = (
  options?: MutationOptions<
    UpdateParentalConsentData,
    GQL.IUpdateParentalConsentOnMutationArguments
  >,
) =>
  useMutationEnhanced<
    UpdateParentalConsentData,
    GQL.IUpdateParentalConsentOnMutationArguments>(
      UPDATE_PARENTAL_CONSENT,
      {
        ...options,
        clearCachePredicates: [
          ...(options?.clearCachePredicates ?? []),
          'myChildrenUnderThirteen',
          'userRelationships',
        ],
        refetchQueries: [
          getOperationName(GET_USER_RELATIONSHIPS) as string,
        ],
      },
    );
