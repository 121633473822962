// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { Subtitle } from 'components/shared';

// Local Dependencies
import MyFinancialPaymentsTable from './MyFinancialPaymentsTable';

// Component Definition
const MyFinancialPayments: FC = () => (
  <>
    <Subtitle>
      My Payments
    </Subtitle>

    <MyFinancialPaymentsTable />
  </>
);

export default MyFinancialPayments;
