// External Dependencies
import { FC, useEffect, useMemo } from 'react';
import { useLocation } from '@reach/router';

// Internal Dependencies
import {
  GET_ORGANIZATION_SCHOOL_YEAR_OPTIONS,
  useGetOrganization,
} from 'gql/queries';
import { navigateSearch } from 'utils/lib/navigate_search';
import { parseSearch } from 'utils';
import { useQueryEnhanced } from 'utils/lib/graphql';
import FilterButton from 'components/shared/FilterButton';

// Local Typings
export interface IFilters {
  schoolYearEnding: number;
  userId: string | null;
}
interface SchoolYearResponse {
  organizationSchoolYearSelectOptions: GQL.ISchoolYearOption[];
}

// Component Definition
const Filters: FC = () => {
  const { search } = useLocation();

  const { data: orgData } = useGetOrganization();

  const parsedSearch = parseSearch(search);

  const localFilters: IFilters = useMemo(() => ({
    schoolYearEnding: parsedSearch.schoolYearEnding
      || orgData?.organization?.currentSchoolYearEnding,
    userId: parsedSearch.userId ?? '',
  }), [
    orgData?.organization?.currentSchoolYearEnding,
    parsedSearch.schoolYearEnding,
    parsedSearch.userId,
  ]);

  // If the URL's search does not have schoolYearEnding,
  //  then we append it based on the orgData's currentSchoolYearEnding
  // This table won't make its network request until
  //  the schoolYearEnding data is in the URL seach params
  useEffect(() => {
    if (orgData && !parsedSearch.schoolYearEnding) {
      navigateSearch(localFilters);
    }
  }, [localFilters, orgData, parsedSearch]);

  const { data } = useQueryEnhanced<SchoolYearResponse>(GET_ORGANIZATION_SCHOOL_YEAR_OPTIONS);

  if (!orgData) {
    return null;
  }

  const handleClickFilter = (
    key: 'schoolYearEnding',
    val: boolean | number | null,
  ) => () => {
    localFilters[key] = val as never;
    navigateSearch({
      ...localFilters,
      page: 1,
    });
  };

  const selectedYear = data?.organizationSchoolYearSelectOptions
    .find((year) => year.id === localFilters.schoolYearEnding);

  return (
    <div>
      {data && (
        <FilterButton
          active={!!localFilters.schoolYearEnding}
          buttonChildren={selectedYear
            ? selectedYear.label
            : 'School Year'}
          id="school-year-ending"
          onClick={null}
          subButtons={data.organizationSchoolYearSelectOptions.map((year) => ({
            onClick: handleClickFilter('schoolYearEnding', year.id),
            selected: selectedYear?.id === year.id,
            text: year.label,
          }))}
        />
      )}
    </div>
  );
};

export default Filters;
