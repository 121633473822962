// External Dependencies
import {
  Box,
  Typography,
} from '@mui/material';
import { FC, useLayoutEffect, useRef } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';

// Internal Dependencies
import { formatDateTime } from 'utils';
import { isMobileScreenSize } from 'state/device/selectors';
import usePrevious from 'hooks/usePrevious';

// Local Dependencies
import {
  StyledCheckCircleIcon,
  StyledChip,
  StyledCircularProgress,
  StyledPaper,
  StyledTimelineDot,
  StyledTypography,
} from './styles';
import BottomContainer from './BottomContainer';

// Local Typings
interface Props {
  historyDataResponse: GQL.IAuditHistory;
  isLoading: boolean;
  onClickShowMoreButton: () => void;
}

// Local Variables
const useStyles = makeStyles({
  missingOppositeContent: {
    '&::before': {
      position: 'absolute',
    },
  },
});

// Component Definition
const HistoryTimeline: FC<Props> = ({
  historyDataResponse,
  isLoading,
  onClickShowMoreButton,
}) => {
  const { palette: { history: { timelineDot } } } = useTheme();
  const classes = useStyles();
  const isMobileScreen = useSelector(isMobileScreenSize);
  const scrollContainerRef = useRef<HTMLUListElement>(null);

  const {
    changes: historyData,
    nextCursor,
  } = historyDataResponse;

  const previousIsLoading = usePrevious(isLoading);

  useLayoutEffect(() => {
    const scrollToVerticalPosition = () => {
      if (scrollContainerRef !== null && scrollContainerRef.current) {
        const verticalPosition = scrollContainerRef.current?.getBoundingClientRect().y;
        scrollContainerRef.current.scrollTo(0, verticalPosition);
      }
    };

    // Scroll to previous bottom only when the loading indicator stops showing
    if (previousIsLoading && !isLoading
      && scrollContainerRef.current) {
      scrollToVerticalPosition();
    }
  }, [previousIsLoading, isLoading]);

  const hasMoreData = !!nextCursor;

  if (!historyData.length) {
    return (
      <BottomContainer>
        <Typography>
          No history data for this resource
        </Typography>
      </BottomContainer>
    );
  }

  return (
    <>
      <Timeline
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
        ref={scrollContainerRef}
      >
        {historyData?.map((datum, idx, fullArray) => {
          const isOldestHistoryItem = !hasMoreData && idx === fullArray.length - 1;

          const formattedDateTime = (
            <Typography variant="body2">
              {formatDateTime(datum.date)}
            </Typography>
          );

          return (
            <TimelineItem
              classes={{
                missingOppositeContent: classes.missingOppositeContent,
              }}
              key={datum.id}
            >
              {!isMobileScreen && (
                <TimelineOppositeContent>
                  {formattedDateTime}
                </TimelineOppositeContent>
              )}
              <TimelineSeparator>
                {isOldestHistoryItem
                  ? <StyledCheckCircleIcon htmlColor={timelineDot} />
                  : <StyledTimelineDot />}
                {!isOldestHistoryItem && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                {isMobileScreen && (
                  <Box mb={1}>
                    {formattedDateTime}
                  </Box>
                )}
                <StyledPaper variant="outlined">
                  <StyledTypography variant="h6">
                    {datum.message}
                  </StyledTypography>
                  {datum?.updates?.map((update: any, index) => (
                    <Typography
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${datum.id}-${index}`}
                      variant="body2"
                    >
                      &#8226; {update}
                    </Typography>
                  ))}
                </StyledPaper>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>

      {isLoading && (
        <BottomContainer>
          <StyledCircularProgress
            size={24}
            thickness={6}
          />
        </BottomContainer>
      )}

      {hasMoreData && !isLoading && (
        <BottomContainer>
          <StyledChip
            clickable
            label="Show More"
            onClick={onClickShowMoreButton}
          />
        </BottomContainer>
      )}
    </>
  );
};

export default HistoryTimeline;
