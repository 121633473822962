// External Dependencies
import { Button } from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { useIsOpen } from 'hooks/useIsOpen';
import GrantParentalConsentDialog from './GrantParentalConsentDialog';
import RevokeParentalConsentDialog from './RevokeParentalConsentDialog';

// Local Typings
interface Props {
  isChecked: boolean;
  userId: string;
}

// Component Definition
const ParentalConsentButton: FC<Props> = ({
  isChecked,
  userId,
}) => {
  const {
    isOpen: isGrantConsentDialogOpen,
    toggleIsOpen: handleToggleIsGrantConsentDialogOpen,
  } = useIsOpen();

  const {
    isOpen: isRevokeConsentDialogOpen,
    toggleIsOpen: handleToggleIsRevokeConsentDialogOpen,
  } = useIsOpen();

  return (
    <>
      <Button
        color="primary"
        onClick={isChecked
          ? handleToggleIsRevokeConsentDialogOpen
          : handleToggleIsGrantConsentDialogOpen}
        size="small"
        variant="outlined"
      >
        {isChecked ? 'Revoke' : 'Allow'}
      </Button>

      <RevokeParentalConsentDialog
        isOpen={isRevokeConsentDialogOpen}
        onClose={handleToggleIsRevokeConsentDialogOpen}
        userId={userId}
      />

      <GrantParentalConsentDialog
        isOpen={isGrantConsentDialogOpen}
        onClose={handleToggleIsGrantConsentDialogOpen}
        userId={userId}
      />
    </>
  );
};

export default ParentalConsentButton;
