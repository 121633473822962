/* eslint-disable graphql/template-strings */
// External Dependencies
import { useQueryEnhanced } from 'utils/lib/graphql';
import gql from 'graphql-tag';

// Local Dependencies
import { FRAGMENT_SIMPLE_UNIFORM, SimpleUniform } from './uniform-queries';
import { SimpleUser, USER_FRAGMENT } from './user-queries';

// Local Typings
export interface UniformCheckoutResponse {
  active: boolean;
  checkinDate: string | null;
  checkoutDate: string;
  dueDate: string;
  id: string;
  uniform: SimpleUniform;
  user: SimpleUser;
}

// This typing is also used by the MyUniformCheckouts query
export interface UniformCheckoutsByIdResponse {
  id: string;
  uniformCheckouts: GQL.IUniformCheckout[];
}

// Fragment Definitions
export const FRAGMENT_UNIFORM_CHECKOUT = gql`
  fragment UniformCheckoutFragment on UniformCheckout {
    active
    checkinDate
    checkoutDate
    dueDate
    id
    uniform {
      ...SimpleUniformFragment
      customBarcode
      systemBarcode
    }
    user {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
  ${FRAGMENT_SIMPLE_UNIFORM}
`;

// Query Definitions
const GET_UNIFORM_CHECKOUT = gql`
  query UniformCheckout($id: ID!) {
    uniformCheckout (id: $id) {
        ...UniformCheckoutFragment
    }
  }
  ${FRAGMENT_UNIFORM_CHECKOUT}
`;

const GET_CHECKOUTS_BY_USER_ID = gql`
  query UniformCheckoutsByUserId($userId: ID!) {
    uniformCheckoutsByUserId (userId: $userId) {
      id
      uniformCheckouts {
        ...UniformCheckoutFragment
      }
    }
  }
  ${FRAGMENT_UNIFORM_CHECKOUT}
`;

const GET_MY_UNIFORM_CHECKOUTS = gql`
  query MyUniformCheckouts {
    myUniformCheckouts {
      id
      uniformCheckouts {
        ...UniformCheckoutFragment
      }
    }
  }
  ${FRAGMENT_UNIFORM_CHECKOUT}
`;

export const useGetUniformCheckout = (id?: string) => useQueryEnhanced<{
  uniformCheckout: UniformCheckoutResponse;
}>(
  GET_UNIFORM_CHECKOUT,
  {
    skip: !id,
    variables: { id },
  },
);

export const useGetUniformCheckoutsByUserId = (userId?: string) =>
  useQueryEnhanced<{
    uniformCheckoutsByUserId: UniformCheckoutsByIdResponse;
  }>(
    GET_CHECKOUTS_BY_USER_ID,
    {
      skip: !userId,
      variables: { userId },
    },
  );
export const useGetMyUniformCheckouts = () =>
  useQueryEnhanced<{
    myUniformCheckouts: UniformCheckoutsByIdResponse;
  }>(GET_MY_UNIFORM_CHECKOUTS);
