// External Dependencies
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import ArrowRightThickIcon from 'mdi-material-ui/ArrowRightThick';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  ConfirmationDialog,
  EnhancedAlert,
  EnhancedCard,
  ListItemWithSecondaryAction,
} from 'components/shared';
import { formatDateTime } from 'utils';
import { toggleOrganization } from 'utils/api';
import { useIsOpen } from 'hooks/useIsOpen';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
})) as typeof Typography;

const StyledEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

// Component Definition
const FindOrganization: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgName, setOrgName] = useState('');

  const dispatch = useDispatch();

  const {
    handleClose,
    handleOpen,
    isOpen,
  } = useIsOpen();

  const { self } = useSelfQuery();

  const toggleOrg = (memberId: string | null) => async () => {
    try {
      setIsLoading(true);
      if (memberId) {
        await toggleOrganization(memberId, dispatch);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!self?.pendingOrganizationMemberships.length && !self?.userOrgData.length) {
    return null;
  }

  const handleOpenDialog = (pendingOrgName: string) => {
    setOrgName(pendingOrgName);
    handleOpen();
  };

  return (
    <>
      <Box
        marginTop={4}
        maxWidth={600}
        width="auto"
      >
        {!!self?.pendingOrganizationMemberships?.length && (
          <>
            <StyledTypography
              component="h3"
              variant="h6"
            >
              Pending Membership Requests
            </StyledTypography>

            <StyledEnhancedCard>
              <CardContent>
                <>
                  <EnhancedAlert
                    sx={{
                      marginBottom: 3,
                      textAlign: 'left',
                    }}
                    title="Pending director approval"
                  >
                    Please check with your director regarding your membership status
                  </EnhancedAlert>

                  <List>
                    {self.pendingOrganizationMemberships.map((pendingMembership) => (
                      <ListItemWithSecondaryAction
                        key={pendingMembership.id}
                        primaryText={pendingMembership.organizationLabel}
                        secondaryAction={{
                          buttonIcon: <ArrowRightThickIcon />,
                          buttonSize: 'medium',
                          buttonText: 'View',
                          onClick: () => handleOpenDialog(pendingMembership.organizationLabel),
                        }}
                        secondaryText={(
                          <Typography
                            gutterBottom
                            variant="body2"
                          >
                            Requested at {formatDateTime(pendingMembership.requestedAt)}
                          </Typography>
                        )}
                      />
                    ))}
                  </List>
                </>
              </CardContent>
            </StyledEnhancedCard>
          </>
        )}

        {!!self?.userOrgData?.length && (
          <>
            <StyledTypography
              component="h3"
              sx={{ mt: 2 }}
              variant="h6"
            >
              Active Memberships
            </StyledTypography>

            <StyledEnhancedCard>
              <CardContent>
                <>
                  <EnhancedAlert
                    sx={{
                      marginBottom: 3,
                      textAlign: 'left',
                    }}
                    title="Get Started"
                  >
                    <Typography
                      gutterBottom
                      variant="body2"
                    >
                      Choose an organization below to get started.
                    </Typography>

                    <Typography
                      gutterBottom
                      variant="body2"
                    >
                      The next time you sign in, you&apos;ll
                      be taken directly to a {APP_NAME} organization.
                    </Typography>
                  </EnhancedAlert>
                  <List>
                    {self.userOrgData.map((userOrg) => (
                      <ListItemWithSecondaryAction
                        key={userOrg.organizationId}
                        primaryText={userOrg.organization.label}
                        secondaryAction={{
                          buttonIcon: <ArrowRightThickIcon />,
                          buttonSize: 'medium',
                          buttonText: 'View',
                          disabled: isLoading,
                          onClick: toggleOrg(userOrg.member?.id ?? null),
                        }}
                        secondaryText={`${userOrg.organization.entityType.label} ${userOrg.organization.organizationType.label}`}
                      />
                    ))}
                  </List>
                </>
              </CardContent>
            </StyledEnhancedCard>
          </>
        )}
      </Box>

      <ConfirmationDialog
        confirmButtonAction={handleClose}
        description={(
          <>
            Please check with the director of <strong>{orgName}</strong>{' '}
            regarding your membership status.
          </>
        )}
        handleClose={handleClose}
        hideDeclineButton
        open={isOpen}
        title="Your membership is pending"
      />
    </>
  );
};

export default FindOrganization;
