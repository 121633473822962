// External Dependencies
import { FC } from 'react';
import { Typography } from '@mui/material';

// Local Typings
interface Props {
  condition?: boolean;
  message?: string;
}

// Component Definition
const ErrorMessage: FC<Props> = ({
  condition,
  message,
}) => {
  if (!condition) {
    return null;
  }

  return (
    <Typography color="error">
      {message}
    </Typography>
  );
};

export default ErrorMessage;
