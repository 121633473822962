// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { useUpdateParentalConsent } from 'gql/mutations';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

// Component Definition
const RevokeParentalConsentDialog: FC<Props> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const [
    updateParentalConsent,
    {
      loading: isSubmitting,
    },
  ] = useUpdateParentalConsent({
    onCompleted: onClose,
  });

  const handleConfirm = () => {
    updateParentalConsent({
      variables: {
        input: {
          grantPermission: false,
          userId,
        },
      },
    });
  };

  return (
    <ConfirmationDialog
      confirmButtonAction={handleConfirm}
      confirmButtonText="Yes, revoke"
      declineButtonAction={onClose}
      description="Your child will no longer be able to login to Presto Assistant."
      handleClose={onClose}
      isSubmitting={isSubmitting}
      open={isOpen}
      title="Revoke consent?"
    />
  );
};

export default RevokeParentalConsentDialog;
