// External Dependencies
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography,
} from '@mui/material';
import { FC } from 'react';

// Internal Dependencies
import { APP_NAME, PRIVACY_POLICY_HREF } from 'utils/constants';
import { StyledLink } from 'components/shared';
import DataInfo from 'pages/Onboarding/OnboardingAcceptableUseAgreement/DataInfo';
import InfoSection from 'pages/Onboarding/OnboardingAcceptableUseAgreement/InfoSection';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const ParentalConsentInfoDialog: FC<Props> = ({ isOpen, onClose }) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth="md"
  >
    <DialogTitle>
      {APP_NAME} Data Policy
    </DialogTitle>
    <DialogContent>
      <DataInfo />

      <Box marginBottom={2}>
        <Divider />
      </Box>

      <InfoSection>
        <Typography>
          More information can be found in our{' '}
          <StyledLink
            href={PRIVACY_POLICY_HREF}
            openInNewTab
          >
            Privacy Policy
          </StyledLink>
        </Typography>
      </InfoSection>
    </DialogContent>

    <DialogActions>
      <Button
        color="primary"
        onClick={onClose}
        variant="contained"
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

export default ParentalConsentInfoDialog;
