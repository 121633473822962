import { FC } from 'react';
import EmailComposer from 'pages/Communication/EmailComposer';

interface Props {
  id: string;
}

const EmailDraft: FC<Props> = ({
  id,
}) => (
  <EmailComposer draftId={id} />
);

export default EmailDraft;
