// External Dependencies
import {
  Avatar, CardContent, CardHeader, Typography,
} from '@mui/material';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';

// Local Dependencies
import UnclaimedMembersList from './UnclaimedMembersList';

// Local Typings
interface Props {
  unclaimedMembers: GQL.IMember[];
}

// Component Definition
const UnclaimedMembersCard: FC<Props> = ({
  unclaimedMembers,
}) => {
  const theme = useTheme();

  return (
    <EnhancedCard>
      <CardHeader
        avatar={(
          <Avatar
            className="avatar"
            sx={{
              backgroundColor: theme.palette.stripeBlue['300'],
            }}
          >
            <PersonAddAlt1Icon aria-label="Unclaimed members" />
          </Avatar>
        )}
        subheader="The following members are not yet claimed and match your email address."
        title={(
          <Typography
            component="h2"
            variant="h6"
          >
            Unclaimed Members
          </Typography>
        )}
      />

      <CardContent>
        <UnclaimedMembersList
          shouldSwitchToMember={false}
          unclaimedMembers={unclaimedMembers}
        />
      </CardContent>
    </EnhancedCard>
  );
};

export default UnclaimedMembersCard;
