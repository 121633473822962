// External Dependencies
import {
  CardContent,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import styled from 'styled-components';

// Internal Dependencies
import { EnhancedCard } from 'components/shared';
import { navigateSearch } from 'utils/lib/navigate_search';
import { parseSearch } from 'utils';
import { useHasRelationships } from 'hooks/useHasRelationships';
import { useMyFilesSelfData } from 'hooks/useMyFilesSelfData';
import ChildSelect from 'components/shared/Selectors/ChildSelect';

// Local Dependencies
import MyFilesTableUI from './MyFilesTableUI';

// Local Variables
const SectionSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),
})) as typeof Typography;

// Reminder: Parents can be in a group, so we need to show their files

// Component Definition
const MyFilesTable: FC = () => {
  const { search } = useLocation();
  const parsedSearch = parseSearch(search);

  // We use the "memberId" from the url's params
  //  to set the initial state for memberId
  const {
    // Filters add the schoolYearEnding param to the url for us
    schoolYearEnding,
    userId: userIdFromParams,
  } = parsedSearch;

  const selfData = useMyFilesSelfData();

  // We default the table to the current user's files
  const [userId, setUserId] = useState(selfData?.selfId || userIdFromParams);

  // If the memberId from the url has changed,
  //  we update the url params and reset the current page
  useEffect(() => {
    navigateSearch({
      page: 1,
      userId,
    });
  }, [userId]);

  // Parent user needs to check for relationships in this org
  const hasRelationships = useHasRelationships();

  if (!selfData && !userId) {
    return null;
  }

  let selectElement;

  // if parent with relationships, show the select
  //  and default to the parent's MyFiles table
  if (!selfData?.isStudent && hasRelationships) {
    selectElement = (
      <EnhancedCard sx={{ mb: 2 }}>
        <CardContent>
          <SectionSubtitle
            component="h2"
            variant="subtitle1"
          >
            Select a person
          </SectionSubtitle>

          <ChildSelect
            handleChangeUserId={setUserId}
            userId={userId}
          />
        </CardContent>
      </EnhancedCard>
    );
  }

  // if parent with no students, show MyFiles table
  // if student, show MyFiles table

  return (
    <>
      {selectElement}

      <MyFilesTableUI
        schoolYearEnding={schoolYearEnding}
        userId={userId}
      />
    </>
  );
};

export default MyFilesTable;
