// External Dependencies
import { DocumentNode } from 'graphql';
import { ObjectSchema } from 'yup';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { formatYupErrors } from 'utils/lib/formatYupErrors';
import { pluralize } from 'utils';
import { updateIsPaginatedListDataLoaded } from 'state/table/actions';
import { useMutationEnhanced } from 'utils/lib/graphql';
import useTextField from 'hooks/useTextField';

// Local Typings
interface ValidationSchemaProps<MutationPayload extends object,
PayloadToValidate extends object> {
  selectPayloadToValidate: (mutationPayload: MutationPayload) => PayloadToValidate;
  validationSchema: ObjectSchema<PayloadToValidate>;
}

export interface DeleteDialogV2Props<MutationPayload extends object,
PayloadToValidate extends object = {}> {
  actionVerb?: 'Delete' | 'Remove' | string;
  clearCachePredicates?: string[];
  context?: [string] | [singularContext: string, pluralContext: string, parentContext: string];
  descriptionText?: string;
  formatPayload: (note: string | null) => MutationPayload;
  hasSelection?: boolean;
  idCount?: number;
  isOpen: boolean;
  mutation: DocumentNode;
  onClose: () => void;
  onRemoveSelectionAll?: () => void;
  onSuccess?: () => void;
  refetchQueries?: () => string[];
  validationSchemaProps?: ValidationSchemaProps<MutationPayload, PayloadToValidate>;
  withNote?: boolean;
}

// Component Definition
function DeleteDialogV2<MutationPayload extends object>({
  actionVerb = 'Delete',
  clearCachePredicates = [],
  context = ['item'],
  descriptionText,
  formatPayload,
  hasSelection = true,
  idCount = 1,
  isOpen,
  mutation,
  onClose,
  onRemoveSelectionAll,
  onSuccess,
  refetchQueries,
  validationSchemaProps,
  withNote,
}: DeleteDialogV2Props<MutationPayload>) {
  const deletedNoteField = useTextField();

  const dispatch = useDispatch();

  const [singularContext, pluralContext = `${singularContext}s`, parentContext] = context;

  const description = descriptionText ?? `
    You'll no longer be able to view
    ${pluralize(idCount, `this ${singularContext}`, `these ${pluralContext}`)}${parentContext ? ` in this ${parentContext}.` : '.'}
  `;

  const handleCompleted = () => {
    dispatch(updateIsPaginatedListDataLoaded({
      isPaginatedListDataLoaded: false,
    }));

    onClose();

    if (onSuccess) {
      onSuccess();
    }

    if (hasSelection && onRemoveSelectionAll) {
      onRemoveSelectionAll();
    }
  };

  const [
    deleteMutation,
    {
      loading: isSubmitting,
    },
  ] = useMutationEnhanced(
    mutation,
    {
      awaitRefetchQueries: true,
      clearCachePredicates,
      onCompleted: handleCompleted,
      refetchQueries,
    },
  );

  const handleDelete = async () => {
    const mutationPayload = formatPayload(withNote ? deletedNoteField.value : null);

    if (validationSchemaProps) {
      const payloadToValidate = validationSchemaProps
        .selectPayloadToValidate(mutationPayload);

      try {
        await validationSchemaProps.validationSchema
          .validate(payloadToValidate, { abortEarly: false, strict: true });
      } catch (error) {
        const errorMessageObject = formatYupErrors(error);
        const errorMessages = Object.values(errorMessageObject);

        errorMessages.forEach((errorMessage) => {
          dispatch(addNotification(errorMessage, 'error'));
        });

        return;
      }
    }

    // This is where validations for payload should occur
    deleteMutation({
      variables: mutationPayload,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      deletedNoteField.onReset();
    }
  }, [deletedNoteField, isOpen]);

  const title = `${actionVerb} ${pluralize(idCount, `this ${singularContext}`, `these ${pluralContext}`)}?`;

  return (
    <ConfirmationDialog
      confirmButtonAction={handleDelete}
      confirmButtonText={`Yes, ${actionVerb}`}
      declineButtonAction={onClose}
      deletedNoteInputProps={withNote ? deletedNoteField : undefined}
      description={description}
      handleClose={onClose}
      isSubmitting={isSubmitting}
      open={isOpen}
      title={title}
    />
  );
}

export default DeleteDialogV2;
