// External Dependencies
import { IconButton, InputAdornment } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { updatePasswordSchema } from '@presto-assistant/api_types/schemas/self';
import { useDispatch, useSelector } from 'react-redux';
import VisiblityIcon from '@mui/icons-material/Visibility';
import VisiblityOffIcon from '@mui/icons-material/VisibilityOff';

// Internal Dependencies
import {
  CustomInput,
  DialogForm,
} from 'components/shared';

import { GET_SELF } from 'gql/queries';
import { addNotification } from 'state/notifications/actions';
import { close } from 'state/ui/updatePasswordDialog/actions';
import { isOpen } from 'state/ui/updatePasswordDialog/selectors';
import { useUpdatePassword } from 'gql/mutations';
import { validatePasswordChange } from 'utils/forms/self';

// Local Typings
interface UpdatePasswordDialogsFormValues {
  confirmPassword: string;
  oldPassword: string;
  password: string;
}

// Component Definition
const UpdatePasswordDialog = () => {
  const dispatch = useDispatch();
  const isDialogOpen = useSelector(isOpen);

  const handleClose = () => {
    dispatch(close());
  };

  const handleSuccess = () => {
    dispatch(addNotification('Password updated', 'success'));
    handleClose();
  };

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [updatePassword] = useUpdatePassword({
    onCompleted: handleSuccess,
    refetchQueries: [{ query: GET_SELF }],
  });

  const handleClickShowPassword = () => {
    setShowPassword((state) => !state);
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword((state) => !state);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((state) => !state);
  };

  const handleMouseDownPassword = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (values: UpdatePasswordDialogsFormValues) => {
    await updatePassword({
      variables: {
        input: values,
      },
    });
  };

  return (
    <DialogForm<UpdatePasswordDialogsFormValues>
      dialogProps={{
        maxWidth: 'sm',
        open: isDialogOpen,
      }}
      initialValues={{
        confirmPassword: '',
        oldPassword: '',
        password: '',
      }}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Update Password"
      validate={validatePasswordChange}
      validationSchema={updatePasswordSchema}
    >
      <CustomInput
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle current password visibility"
                onClick={handleClickShowCurrentPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showCurrentPassword ? <VisiblityIcon /> : <VisiblityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Current Password"
        name="oldPassword"
        type={showCurrentPassword ? 'text' : 'password'}
      />
      <CustomInput
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword
                  ? <VisiblityIcon />
                  : <VisiblityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText="Must contain a letter, number, no spaces, and be at least 8 characters"
        label="New Password"
        name="password"
        type={showPassword ? 'text' : 'password'}
      />
      <CustomInput
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showConfirmPassword
                  ? <VisiblityIcon />
                  : <VisiblityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Confirm Password"
        name="confirmPassword"
        type={showConfirmPassword ? 'text' : 'password'}
      />
    </DialogForm>
  );
};

export default UpdatePasswordDialog;
