// External Dependencies
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { ForwardButton } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';

// Local Typings
interface Props {
  organizationId: string;
}

// Component Definition
const LinkToAdultsWithPermissions: FC<Props> = ({ organizationId }) => {
  const districtParentsWithPermissionsParams = useSelector(
    tableQueryParams('districtOrganizationsParentsWithPermissions'),
  );

  return (
    <Box
      ml={2}
      mt={4}
    >
      <Typography
        role="heading"
        sx={{ mb: 2 }}
        variant="body2"
      >
        View all adults in this organization with expanded permssions
      </Typography>

      <ForwardButton navigateTo={`${organizationId}/${PATHS.PEOPLE}/${PATHS.ADULTS_WITH_PERMISSIONS}${districtParentsWithPermissionsParams}`}>
        Adults With Permissions
      </ForwardButton>
    </Box>
  );
};

export default LinkToAdultsWithPermissions;
