// External Dependencies
import {
  GridColDef, GridPreProcessEditCellProps,
} from '@mui/x-data-grid-pro';
import { updateUserSchema } from '@presto-assistant/api_types/schemas/user';
import {
  useMemo,
} from 'react';

// Internal Dependencies
import { formatPhoneNumber, runValidationSchema } from 'utils';
import { renderEditCell } from 'components/shared/TableDataGrid/helpers';

export const useColumns = (
  extraColumns?: GridColDef[],
) => useMemo(() => {
  const preProcessEditCellProps = async (
    params: GridPreProcessEditCellProps,
    fieldName: keyof GQL.IUpdateUserInput,
  ) => {
    const value = params.props.value!.toString();

    // Validate like Formik does
    const errorMessage: any = await runValidationSchema<GQL.IUpdateUserInput>(
      value,
      fieldName,
      updateUserSchema as any,
    );

    return {
      ...params.props,
      error: errorMessage[fieldName as keyof GQL.IUpdateUserInput],
    };
  };
  const columns: GridColDef[] = [
    {
      editable: true,
      field: 'firstName',
      headerName: 'First Name',
      preProcessEditCellProps: (params) => preProcessEditCellProps(
        params,
        'firstName',
      ),
      renderEditCell,
      width: 160,
    },
    {
      editable: true,
      field: 'middleName',
      headerName: 'Middle Name',
      preProcessEditCellProps: (params) => preProcessEditCellProps(
        params,
        'middleName',
      ),
      renderEditCell,
      width: 160,
    },
    {
      editable: true,
      field: 'lastName',
      headerName: 'Last Name',
      preProcessEditCellProps: (params) => preProcessEditCellProps(
        params,
        'lastName',
      ),
      renderEditCell,
      width: 160,
    },
    {
      editable: true,
      field: 'email',
      headerName: 'Email',
      preProcessEditCellProps: (params) => preProcessEditCellProps(
        params,
        'email',
      ),
      renderEditCell,
      width: 292,
    },
    {
      editable: true,
      field: 'phoneNumber',
      headerName: 'Phone Number',
      preProcessEditCellProps: (params) => preProcessEditCellProps(
        params,
        'phoneNumber',
      ),
      valueFormatter: (params) => formatPhoneNumber(params.value),
      width: 292,
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
